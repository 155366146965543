<template>
  <div>
    <h6>Logs</h6>
    <div class="log" style="max-height: 400px !important;">
      <v-table :columns="columns">
        <tr v-for="(item, index) in logsdata" :key="index">
          <td style="width: fit-content;">{{ ++index }}</td>
          <td>
            {{ datetimeFormatAmPm(item.created_at) }}
          </td>
          <td>
            <div>
              <li
                v-for="(description, key, index) in item.detail_description"
                :key="index"
              >
                {{ key }}:
                {{ description }}
              </li>
            </div>
          </td>
          <td style="width:5rem">
            {{ item.updated_by_user }}
          </td>
        </tr>
      </v-table>
    </div>
  </div>
</template>

<script>
import vTable from "@/includes/table/Datatable";
export default {
  props: ["logsdata"],
  components: {
    vTable
  },
  data() {
    return {
      columns: [
        {
          name: "sn",
          width: "",
          label: "S.N",
          class: ""
        },
        {
          name: "date",
          width: "",
          label: "Date",
          class: ""
        },
        {
          name: "description",
          width: "",
          label: "Description",
          class: ""
        },
        {
          name: "created_by",
          width: "",
          label: "Updated By",
          class: ""
        }
      ]
    };
  }
};
</script>
