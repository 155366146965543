<template>
  <div>
    <h6>Select Company</h6>

    <div style="max-height: 400px !important">
      <multiselect
        :key="1"
        :options="companydata"
        placeholder="Select Company "
        label="company_name"
        track-by="id"
        :preselect-first="false"
        v-model="company"
      ></multiselect>
      <div class="card-footer">
        <button
          class="btn btn-success mr-2 mt-3 btn-right"
          style="float: right"
          @click="postData()"
        >
          Save
        </button>
        <button
          class="btn btn-dark mr-2 mt-3 btn-right"
          style="float: right"
          @click="backtologs()"
        >
          Go Back
        </button>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["companydata"],

  components: {
    Multiselect,
  },

  data() {
    return { company: {} };
  },

  methods: {
    postData() {
      this.$emit("postdata", this.company.id, this.company.company_name);
    },
    backtologs() {
      this.$emit("backtologs");
    },
  },
};
</script>
