<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .modal-content {
    min-height: 40rem;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Detail of Enrollment Number: ' + enrollmentData.id"
  >

    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get your enrollment details.</h6>
      </div>
    </div>
    <div class="row" v-if="hasData && !loadingData">
      <div class="col-12" style="padding: 10px">
        <div class="row mb-3">
          <div class="col-6">
            <ul style="display: flex; list-style: none">
              <li style="width: 100%">
                <h5>{{ enrollmentData.service_name }}</h5>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <!-- <div class="refund-service mt-5">
              <button
                v-if="
                  currentUser.u.is_service_provider &&
                    (enrollmentData.service_type == 'fdle' ||
                      enrollmentData.service_type == 'fingerprintcard')
                "
                class="btn btn-secondary mr-2"
                @click="
                  changeEnrollmentCompany(
                    enrollmentData.id,

                    enrollmentData.client_id
                  )
                "
              >
                Change Company
              </button>
            </div> -->
<!-- 
            <div class="refund-service">
              <button
                v-if="
                  currentUser.u.is_service_provider &&
                    (enrollmentData.service_type == 'fdle' ||
                      enrollmentData.service_type == 'fingerprintcard')
                "
                class="btn btn-secondary mr-2"
                @click="show_company()"
              >
                Assign Company
              </button>
              <button
                v-if="
                  currentUser.u.is_service_provider &&
                    (enrollmentData.service_type == 'fdle' ||
                      enrollmentData.service_type == 'fingerprintcard')
                "
                class="btn btn-secondary mr-2"
                @click="
                  changeEnrollmentArchive(
                    enrollmentData.enrollment_id,
                    enrollmentData.tcn,
                    false
                  )
                "
              >
                <span
                  v-if="
                    enrollmentData.archival_expiry_date &&
                      enrollmentData.archival_expiry_date != ''
                  "
                >
                  Renew Archive
                </span>
                <span v-else> Archive </span>
              </button>
            </div> -->
          </div>
        </div>

        <hr />
        <div class="row mt-3">
          <div class="col-4">
            <p class="text-success sub-header">Personal Details</p>
            <ul class="list-unstyled">
              <li>
                <label for="">First Name:</label>
                <label for="" class="detail">{{ enrollmentData.fname }}</label>
              </li>
              <li>
                <label for="">Last Name: </label>
                <label for="" class="detail">{{ enrollmentData.lname }}</label>
              </li>
              <li>
                <label for="">Email:</label>
                <label for="" class="detail">{{ enrollmentData.email }}</label>
              </li>
              <li>
                <label for="">Contact:</label>
                <label for="" class="detail"
                  >{{ enrollmentData.phone_no }}
                </label>
              </li>

              <li>
                <label for="">Address:</label>
                <label for="" class="detail"
                  >{{ enrollmentData.address1 }}, {{ enrollmentData.city }}
                </label>
              </li>

              <!-- <li>
                    <label for="">Date of Birth: </label>
                    <label for="" class="detail">{{
                      enrollmentData.dob
                    }}</label>
                  </li> -->
            </ul>
            <p class="text-success sub-header">Enrollment Details</p>
            <div>
              <label for="">Enroll Date: </label>
              <label
                for=""
                class="detail ml-2"
                v-if="
                  enrollmentData.enroll_date != '' &&
                    enrollmentData.enroll_date != null
                "
              >
                <strong>
                  {{ dateFormat(enrollmentData.enroll_date) }}</strong
                ></label
              >
              <label for="" class="detail ml-2" v-else>
                <strong> - </strong></label
              >
            </div>
            <div>
              <label for="">ORI: </label>
              <label for="" class="detail">
                <strong> {{ enrollmentData.ori }}</strong></label
              >
            </div>
            <div>
              <label for="">CRI: </label>
              <label for="" class="detail">
                <strong> {{ enrollmentData.cri }}</strong></label
              >
            </div>

            <div
              v-if="
                enrollmentData.tcn_list == '' || enrollmentData.tcn_list == null
              "
            >
              <label for="">TCN: </label>
              <li>
                <span
                  v-if="
                    enrollmentData.transmit_status == '100' ||
                      enrollmentData.transmit_status == '400'
                  "
                >
                  <strong>{{ enrollmentData.tcn }}</strong> (Draft)
                </span>
                <span
                  v-else-if="
                    enrollmentData.service_type == 'fingerprintcard' &&
                      (enrollmentData.transmit_status == '200' ||
                        enrollmentData.transmit_status == '500')
                  "
                >
                  <strong>{{ enrollmentData.tcn }}</strong> (Final)
                </span>
                <span
                  v-else-if="
                    enrollmentData.service_type != 'fingerprintcard' &&
                      (enrollmentData.transmit_status == '200' ||
                        enrollmentData.transmit_status == '500')
                  "
                >
                  <strong>{{ enrollmentData.tcn }}</strong> (Ready To Transmit)
                </span>
                <span
                  v-else-if="
                    enrollmentData.transmit_status == '300' ||
                      enrollmentData.transmit_status == '600'
                  "
                >
                  <strong>{{ enrollmentData.tcn }}</strong> (Transmitted)
                </span>
                <span v-else>
                  {{ enrollmentData.tcn }}
                </span>
              </li>
            </div>

            <div v-else>
              <label for="">TCN: </label>

              <li>
                <strong>{{ enrollmentData.tcn }}</strong>
                <p>
                  Transmitted at:
                  {{ datetimeFormatAmPm(enrollmentData.tcn_list.created_at) }}
                </p>
              </li>
            </div>

            <div
              v-if="
                (enrollmentData.tcr_list == '' ||
                  enrollmentData.tcr_list == null) &&
                  enrollmentData.tcr != null &&
                  enrollmentData.tcr != ''
              "
            >
              <label for="">TCR: </label>
              <li>
                <strong>{{ enrollmentData.tcr }}</strong>
              </li>
            </div>

            <div
              v-else-if="enrollmentData.tcr != '' && enrollmentData.tcr != null"
            >
              <label for="">TCR: </label>

              <li>
                <strong>{{ enrollmentData.tcr }}</strong>
                <p>
                  Transmitted at:
                  {{ datetimeFormatAmPm(enrollmentData.tcr_list.created_at) }}
                </p>
              </li>
            </div>
            <p class="text-success sub-header mt-5">Appointment Details</p>
            <div>
              <label for="">Appointment No. : </label>

              <strong
                class="ml-2"
                v-if="
                  enrollmentData.appointment_id != '' &&
                    enrollmentData.appointment_id != null
                "
                >{{ enrollmentData.appointment_id }}</strong
              >
              <strong v-else class="ml-2">-</strong>
            </div>
            <div>
              <label for="">Schedule Date : </label>

              <strong
                class="ml-2"
                v-if="
                  enrollmentData.schedule_date != '' &&
                    enrollmentData.schedule_date != null
                "
                >{{ enrollmentData.schedule_date }}</strong
              >
              <strong v-else class="ml-2">-</strong>
            </div>

            <!-- <div>
              <label for="">ORI: </label>
              <label for="" class="detail">
                <strong> {{ enrollmentData.ori }}</strong></label
              >
            </div>
            <div>
              <label for="">CRI: </label>
              <label for="" class="detail">
                <strong> {{ enrollmentData.cri }}</strong></label
              >
            </div> -->
          </div>
          <div class="col-8">
            <enrollmentlogs
              v-if="current_display_logs"
              :logsdata="getenrollmentLog"
            >
            </enrollmentlogs>
            <enrollassigncompany
              v-if="!current_display_logs"
              :companydata="filteredCompanies"
              :enrollmentData="enrollmentData"
              v-on:postdata="funPostDate"
              v-on:backtologs="backtologs"
            >
            </enrollassigncompany>
            <!-- <enrolmentlogs v-if="display_company"
            :logsData = getenrollmentLog> -->
          </div>

          <!-- <div class="col-8" v-if="view_logs">
            <h6>Logs</h6>

            <div class="log">
              <v-table :columns="columns">
                <tr v-for="(item, index) in getenrollmentLog" :key="index">
                  <td>{{ ++index }}</td>
                  <td>
                    {{ datetimeFormatAmPm(item.created_at) }}
                  </td>
                  <td>
                    <div>
                      <li
                        v-for="(description,
                        key,
                        index) in item.detail_description"
                        :key="index"
                      >
                        {{ key }}:
                        {{ description }}
                      </li>
                    </div>
                  </td>
                  <td style="width:5rem">
                    {{ item.updated_by_user }}
                  </td>
                </tr>
              </v-table>
            </div>
          </div> -->
        </div>
        <hr />
        <div
          class="row"
          v-if="
            enrollmentData.service_type == 'deporder' ||
              enrollmentData.service_type == 'authrcp'
          "
          style="padding: 10px"
        >
          <div v-if="enrollmentData.msg_filedata != ''" class="col-md-6">
            <p class="text-success sub-header">Message</p>
            <div style="border: 1px solid gray; word-wrap: break-word">
              <div
                class="ml-2"
                v-for="(description,
                index) in enrollmentData.msg_filedata
                  .split('\n')
                  .splice(
                    0,
                    enrollmentData.msg_filedata.split('\n').length - 1
                  )"
                :key="index"
                v-show="description !== ''"
              >
                {{ description }}
              </div>
            </div>
          </div>

          <div v-if="enrollmentData.res_filedata != ''" class="col-md-6">
            <p class="text-success sub-header">Acknowledgement</p>

            <div style="border: 1px solid gray; word-wrap: break-word">
              <div
                class="ml-2"
                v-for="(description,
                index) in enrollmentData.res_filedata
                  .split('\n')
                  .splice(
                    0,
                    enrollmentData.res_filedata.split('\n').length - 1
                  )"
                :key="index"
                v-show="description !== ''"
              >
                {{ description }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="enrollmentData.service_type == 'fdle'"
          style="padding: 10px"
        >
          <div v-if="enrollmentData.fdle_response != ''" class="col-md-6">
            <p class="text-success sub-header">Fdle Response</p>
            <div style="border: 1px solid gray; word-wrap: break-word">
              <div class="ml-2">
                {{ enrollmentData.fdle_response }}
              </div>
            </div>
          </div>

          <div v-if="enrollmentData.acha_response != ''" class="col-md-6">
            <p class="text-success sub-header">AHCA Response</p>

            <div style="border: 1px solid gray; word-wrap: break-word">
              <div class="ml-2">
                {{ enrollmentData.acha_response }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="!hasData && !loadingData">
        <div class="col-md-12 col-12" style="padding: 10px">
          <h5>Sorry, we didn't found your appointment details</h5>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
  
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";
import enrollmentlogs from "@/components/frontend/modal/enrollmentlogs";
import enrollassigncompany from "@/components/frontend/modal/enrollassigncompany";
// import vTable from "@/includes/table/Datatable";

import moment from "moment";
import { mapGetters } from "vuex";

// import {
//   SET_APPOINTMENT_FOR_RESCHEDULE,
//   SET_TIME_ZONE,
//   SET_SERVICE_ID,
//   SET_BOOK_NOW_FROM_SP,
//   SET_ADD_RATE_QTY,
//   UPDATE_PAYMENT_TYPE,
//   SET_RATE_TOTAL_AMOUNT,
//   APPLICANTDETAIL
// } from "@/core/services/store/mutations.type";
// // import * as PDF417 from "pdf417-generator";
import { ASSIGN_ENROLLMENT_COMPANY } from "@/core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    hasData: {
      type: Boolean,
      default: false
    },
    showQrCode: {
      type: Boolean,
      default: false
    },
    indexNum: {
      type: Number,
      default: -1
    },
    enrollmentData: {
      type: Object,
      default: function() {
        return [];
      }
    },

    token: {
      type: String,
      default: ""
    },
    paymentLog: {
      type: Boolean,
      default: false
    },
    displayLogs: {
      type: Boolean,
      // default: false
    }
  },
  data() {
    return {
      // current_display_logs: false
      current_display_logs: this.displayLogs??false
    };
  },
 
  components: {
    loadingSpinner,
    enrollmentlogs,
    enrollassigncompany
    // vTable
  },
  watch: {
  displayLogs(newVal) {
    this.current_display_logs = newVal;
  }
},
  
  computed: {
    filteredCompanies() {
      let tempMasterCompanies = this.getMasterCompanies;
      tempMasterCompanies = tempMasterCompanies.filter(item => {
        return item.use_in_archive;
      });
      return tempMasterCompanies;
    },
    ...mapGetters([
      "currentUser",
      "getenrollmentLog",
      "getCompanies",
      "getMasterCompanies"
    ])
  },

  methods: {
    funPostDate(companyId, company_name) {
      if (companyId == "") {
        this.$toastr.e("please select a company");
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You want to assign to company " + company_name,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          })
          .then(willChange => {
            if (willChange.isConfirmed) {
              var data_ = {
                id: this.enrollmentData.id,
                selected_company: companyId
              };

              this.$store
                .dispatch(ASSIGN_ENROLLMENT_COMPANY, data_)
                .then(() => {
                  this.$toastr.s("Enrollment updated successfully");

                  this.$emit("refreshtable", this.indexNum, company_name);

                  this.close();
                })
                .catch(err => {
                  this.$toastr.e(err.data.msg);
                });
            }
          });
      }
    },
    backtologs() {
      this.current_display_logs = true;
    },
    close() {
      this.$emit("closemodal");
    },
    show_company() {
      this.current_display_logs = false;
    },
    changeEnrollmentArchive(enroll_id, tcn, onsite) {
      this.$router.push({
        name: "client.changearchive.enrollment",
        params: {
          enroll_id: enroll_id,
          tcn: tcn,
          onsite: onsite
        }
      });
    }
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function(date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    }
  }
};
</script>
